<template>
  <p-app theme="glittr">
    <template slot="header">
      <p-toolbar>
        <p-row align-center dense>
          <p-button
            secondary
            spacious
            @click="toggleSideDrawerVisible()"
          >
            <p-icon> menu </p-icon>
          </p-button>
          <p-img
            width="260"
            height="52"
            contain
            :src="require('@src/assets/logo.png')"
            to="/GreenCross/IL/"
          />
        </p-row>
        <p-row justify-end align-center>
          <il-language-selection></il-language-selection>
          <p-menu v-model="profileMenuVisible" bottom left>
            <template slot="activator">
              <p-button secondary>
                <p-icon>person</p-icon>
              </p-button>
            </template>
            <p-card>
              <p-row column>
                <router-link to="/_me/profile" tag="p-button">
                  {{ $t("core.page.profile.button.showProfile") }}
                </router-link>
                <router-link v-if="isAdmin" to="/_hostadmin/" tag="p-button">
                  {{ $t("core.page.user.select.hostAdmin") }}
                </router-link>
                <p-button @click="doLogoff">
                  {{ $t("core.page.profile.button.logoff") }}
                  <p-icon right>
                    exit_to_app
                  </p-icon>
                </p-button>
              </p-row>
            </p-card>
          </p-menu>
        </p-row>
      </p-toolbar>
    </template>

    <p-side-drawer
      v-model="isSidedrawerVisible"
      :overlay="$media.isMobile"
      :minified.sync="isNavMinifed"
      minifiable
    >
      <template #drawer-content>
        <p-list-group>
          <p-list spacious>
            <p-list-entry
              to="/GreenCross/IL/"
              icon-left="view_list"
            >
              {{ $t("navigation.home.name") }}
            </p-list-entry>
            <p-list-group v-if="isAdmin" icon-left="memory">
              <template #activator>
                Integration
              </template>
              <p-list-entry to="/_admin/processes" icon-left="account_tree" @click="navigationClicked">
                {{ $t("core.page.processes.page.title") }}
              </p-list-entry>
              <p-list-entry :to="`/_admin/integrationlog?dateFrom=${date}`" icon-left="merge_type" @click="navigationClicked">
                {{ $t("core.page.integrationlog.page.title") }}
              </p-list-entry>
              <p-list-entry to="/_admin/contactidmapping" icon-left="social_distance" @click="navigationClicked">
                {{ $t("core.page.contactidmapping.page.title") }}
              </p-list-entry>
              <p-list-entry to="/_admin/fieldmapping" icon-left="compare_arrows" @click="navigationClicked">
                {{ $t('core.page.fieldmapping.page.list.title') }}
              </p-list-entry>
            </p-list-group>
          </p-list>
        </p-list-group>
      </template>
      <template v-if="isAdmin" #drawer-footer-actions>
        <p-button tertiary @click="toggleSettings">
          <p-icon>settings</p-icon>
        </p-button>
      </template>
      <template #content>
        <p-side-drawer v-model="isSettingsVisible" :overlay="$media.isMobile">
          <template #drawer-header-image>
            <p-icon>settings</p-icon>
          </template>
          <template #drawer-header-title>
            {{ $t("navigation.settings.name") }}
          </template>
          <template #drawer-content>
            <p-list-group>
              <p-list class="mt-3" spacious>
                <p-list-entry to="/_admin/dashboard" icon-left="dashboard" @click="settingsClicked">
                  {{ $t('core.page.dashboard.page.title') }}
                </p-list-entry>
                <p-list-entry :to="`/_admin/applog?levels=error&dateFrom=${date}`" icon-left="list" @click="settingsClicked">
                  {{ $t('core.page.appLog.page.title') }}
                </p-list-entry>
                <p-list-entry :to="'/_admin/calllog?datefrom=${date}'" icon-left="list">
                  {{ $t('core.page.callLog.page.title') }}
                </p-list-entry>
                <p-list-entry to="/_admin/users" icon-left="person" @click="settingsClicked">
                  {{ $t('core.page.user.page.title') }}
                </p-list-entry>
                <p-list-entry to="/_admin/sequencenumbers" icon-left="pin" @click="settingsClicked">
                  {{ $t('core.page.sequencenumbers.page.title') }}
                </p-list-entry>
              </p-list>
            </p-list-group>
          </template>
          <template v-if="$media.isMobile" #drawer-footer-actions>
            <p-button tertiary @click="isSettingsVisible = false">
              <p-icon>arrow_back</p-icon>
            </p-button>
          </template>
          <template #content>
            <slot></slot>
          </template>
        </p-side-drawer>
      </template>
    </p-side-drawer>
  </p-app>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    auth: true,
    data: () => ({
      profileMenuVisible: false,
      date: Vue.$date.now().format('YYYY-MM-DD'),
      isSidedrawerVisible: Vue.$media.isNotMobile,
      isSettingsVisible: false,
      isNavMinifed: false,
    }),
    computed: {
      isAdmin(): boolean {
        let isAdmin = false;
        if (this.$auth.isLoggedIn) {
          isAdmin = this.$auth.isInRole('Admin', 'HostAdmin');
        }
        return isAdmin;
      },
    },
    watch: {
      isNavMinifed() {
        this.$localStorage.set('isNavMinifed', this.isNavMinifed);
      },
    },
    beforeMount() {
      if (this.$media.isNotMobile) {
        this.isSidedrawerVisible = this.$localStorage.get<boolean>('isSidedrawerVisible') ?? true;
        this.isSettingsVisible = this.isAdmin && (this.$localStorage.get<boolean>('isSettingsVisible') ?? false);
        this.isNavMinifed = this.$localStorage.get<boolean>('isNavMinifed') ?? false;
        this.$nextTick(() => {
          this.isNavMinifed = this.$localStorage.get<boolean>('isNavMinifed') ?? false;
        });
      }
    },
    methods: {
      navigationClicked() {
        if (this.$media.isMobile) {
          this.toggleSideDrawerVisible(false);
        }
      },
      settingsClicked() {
        if (this.$media.isMobile) {
          this.toggleSettings();
        }
      },
      async doLogoff() {
        try {
          await this.$auth.logout();
          this.$router.push('/_exterior/login');
        } catch (error) {
          this.$log.error('Unable to log out an error occurred');
          this.$log.error(error);
        }
      },
      toggleSettings() {
        this.isSettingsVisible = !this.isSettingsVisible;
        this.$localStorage.set('isSettingsVisible', this.isSettingsVisible);
        if (this.$media.isMobile) {
          this.isSidedrawerVisible = false;
        } else {
          this.isNavMinifed = this.isSettingsVisible;
        }
      },
      toggleSideDrawerVisible(isVisible?: boolean) {
        const newVisibleState = isVisible ?? !this.isSidedrawerVisible;
        this.isSidedrawerVisible = newVisibleState;
        this.$localStorage.set('isSidedrawerVisible', newVisibleState);
      },
    },
  });
</script>
